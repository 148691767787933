import { AuthGuard } from '../guards/auth.guard';
import { CompletedRegistrationGuard } from '../guards/completed-registration.guard.ts';

export const userSettingsRoutes = {
    path: 'user-settings',
    canActivate: [CompletedRegistrationGuard, AuthGuard],
    children: [
        {
            path: '',
            loadComponent: () =>
                import('../../features/user-settings/user-settings.component').then(
                    (m) => m.UserSettingsComponent
                ),
            children: [
                {
                    path: 'account-info',
                    loadComponent: () =>
                        import('../../features/user-settings/settings-account-info/settings-account-info.component').then(
                            (m) => m.SettingsAccountInfoComponent
                        ),
                },
                {
                    path: 'personal-info',
                    loadComponent: () =>
                        import('../../features/user-settings/settings-user-info/settings-user-info.component').then(
                            (m) => m.SettingsUserInfoComponent
                        ),
                },
                {
                    path: 'password',
                    loadComponent: () =>
                        import('../../features/user-settings/settings-password-change/settings-password-change.component').then(
                            (m) => m.SettingsPasswordChangeComponent
                        ),
                },
                {
                    path: 'students-info',
                    loadComponent: () =>
                        import('../../features/user-settings/settings-students-info/settings-students-info.component').then(
                            (m) => m.SettingsStudentsInfoComponent
                        ),
                },
                {
                    path: 'packages-info',
                    loadComponent: () =>
                        import('../../features/user-settings/settings-packages-info/settings-packages-info.component').then(
                            (m) => m.SettingsPackagesInfoComponent
                        ),
                },
                {
                    path: 'notification-alerts',
                    loadComponent: () =>
                        import('../../features/user-settings/settings-notification-alerts/settings-notification-alerts.component').then(
                            (m) => m.SettingsNotificationAlertsComponent
                        ),
                },
                {
                    path: 'invoices-info',
                    children: [
                        {
                            path: '',
                            loadComponent: () =>
                                import('../../features/user-settings/settings-invoices-info/settings-invoices-info.component').then(
                                    (m) => m.SettingsInvoicesInfoComponent
                                ),
                        },
                        {
                            path: 'details',
                            loadComponent: () =>
                                import('../../features/user-settings/settings-invoices-info/settings-invoices-info-details/settings-invoices-info-details.component').then(
                                    (m) => m.SettingsInvoicesInfoDetailsComponent
                                ),
                        },
                    ],
                },
                {
                    path: 'availability-info',
                    loadComponent: () =>
                        import('../../features/user-settings/settings-availability-info/settings-availability-info.component').then(
                            (m) => m.SettingsAvailabilityInfoComponent
                        ),
                },

            ],
        },
    ],
};