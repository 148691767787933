@if (showItemType === menuType.buyPackageDropdown) {
  <ng-container *ngFor="let menuItem of topMenuItems">
    <li *ngIf="menuItem.id === 'packagesMenu'" class="relative hidden md:block align-items-center"
        (mouseleave)="onMouseLeave(menuItem.id)">
      @if (authService.isAuth()) {

        @if (userRole()?.role === IUserRole.PARENT) {
          <a pRipple class="inline-flex  align-items-center
    transition-duration-150
    md:ml-4 mr-2 px-3 cursor-pointer
                buy-package-grad-btn font-medium transition-colors transition-duration-150
    " (mouseenter)="onMouseEnter(menuItem.id)">
            <div class="flex align-items-center justify-content-center gap-2">
              <img src="assets/images/rocket.png" alt="logo" height="24">
              <span class="font-semibold">Buy Package</span>
            </div>
            <i class="pi pi-chevron-down ml-auto lg:ml-3"></i>
          </a>

          <ul #buyPackageMenu *ngIf="menuVisibility[menuItem.id]" class="list-none p-0 m-0 buy-package-grad-btn border-round shadow-0 lg:shadow-2 lg:absolute
                    surface-overlay  origin-top w-full lg:w-12rem" style="left: 24px;">
            <li class="px-3">
              <a pRipple (click)="goToBuyPackage()"
                 class="flex gap-2 py-1 align-items-center justify-content-start cursor-pointer transition-colors transition-duration-150">
                <img src="assets/images/icons/meteorite.png" alt="logo" height="18">
                <span>New Package</span>
              </a>
            </li>
            <li class="px-3">
              <a pRipple (click)="goToBuyPackageExtension()"
                 class="flex gap-2 py-1 align-items-center justify-content-start cursor-pointer transition-colors transition-duration-150">
                <img src="assets/images/icons/shooting-stars.png" alt="logo" height="18">
                <span>Buy an extension</span>
              </a>
            </li>
          </ul>
        }
      }
    </li>
  </ng-container>
}


@if (!authService.isAuth()) {
  <ng-container *ngFor="let menuItem of topMenuItems">
    <ng-container *ngIf="menuItem.position === 'right' && menuItem.guestOnly === true">
  <li class="relative text-900 sm:ml-3" (mouseleave)="onMouseLeave(menuItem.id)">
      <a pRipple data-closeSidebar class=" hidden xl:flex px-0 lg:px-3 py-2 align-items-center hover:opacity-50 font-bold transition-colors
        transition-duration-150 menu-item border-4 {{ menuItem.class }}" [routerLink]="[menuItem.link]">
        <span>{{ menuItem.text }}</span>
      </a>
  </li>
</ng-container>
</ng-container>
}

@if (showItemType === menuType.userDropdown) {

  <ng-container *ngFor="let menuItem of topMenuItems">
    <li class="h-full relative  hidden xl:block" *ngIf="menuItem.id === 'profile'">
      <a pRipple class="cursor-pointer h-full inline-flex align-items-center text-600 py-3 lg:py-0 px-2 border-left-2 lg:border-left-none lg:border-bottom-2 border-transparent
            hover:text-blue-600
            transition-colors transition-duration-150" pStyleClass="@next" enterClass="hidden"
         enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true"
         (click)="toggleDropdown($event)">
        <div class="avatar-circle">
          <img src="assets/images/dummy-image.png" class="lg:mr-0 border-round"
               style="width: 28px; height: 28px"/>
        </div>
        <span class="mx-2 font-medium text-900">Hi {{ user()?.firstName }}</span>
        <i class="pi pi-angle-down"></i>
      </a>
      <div
      [ngClass]="{ 'hidden': !this.menuVisibility['profile'] }"
        class=" static lg:absolute w-full surface-overlay left-0 top-100 z-1 shadow-none lg:shadow-2 origin-top border-round pl-3 lg:pl-0">


        <ul class="list-none p-0 m-0">
          <li *ngFor="let subMenuItem of menuItem.submenu">
            <ng-container *ngIf="this.menuVisibility['profile']">
              <ng-container *ngIf="subMenuItem.link; else callbackContent">

                <a pRipple (click)="navigateTo(subMenuItem)"
                   class="flex no-underline p-3 align-items-center cursor-pointer text-700 hover:surface-50 transition-colors transition-duration-150">
                  <span class="pi {{ subMenuItem.icon }} mr-2"></span> <span>{{ subMenuItem.text }}</span>
                </a>
              </ng-container>
              <ng-template #callbackContent>


                <a pRipple *ngIf="subMenuItem.callback" (click)="subMenuItem.callback($event)"
                   class="flex no-underline p-3 align-items-center cursor-pointer text-700 hover:surface-50 transition-colors transition-duration-150">
                  <span class="pi {{ subMenuItem.icon }} mr-2"></span> <span>{{ subMenuItem.text }}</span>
                </a>
              </ng-template>
            </ng-container>

          </li>
        </ul>

      </div>
    </li>
  </ng-container>

}
