import { Router, type CanActivateChildFn, type CanActivateFn } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export const rolesGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const user = authService.userDecodedJWTData$();
  const requiredRole = route.data['role'] as string;

  if (requiredRole.includes(user?.role!)) {
    return true;
  }
  else {
    authService.goToDashboardPerRole();
    // alert('You do not have permission to access this page');
    // authService.goToDashboardPerRole();
    return true;
  }
  return true;
};
