import {
  ApplicationConfig,
  CSP_NONCE,
  ErrorHandler,
  importProvidersFrom,
  provideZoneChangeDetection
} from '@angular/core';
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { CustomErrorHandler } from './core/helpers/custom-error-handler';
import { MessageService } from 'primeng/api';
import { httpErrorInterceptor } from './core/interceptors/http-error.interceptor';

const globalThis = window; // Adjust if using a different global object

function generateRandomNonce() {
  const array = new Uint32Array(10);
  crypto.getRandomValues(array);
  return array.join('');
}

const myRandomNonceValue = generateRandomNonce();

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'top',
  anchorScrolling: 'enabled',
};
const inMemoryScrollingFeature: InMemoryScrollingFeature =
  withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    MessageService,
    provideRouter(routes, inMemoryScrollingFeature),
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    importProvidersFrom(BrowserModule),
    provideAnimations(),
    provideZoneChangeDetection({
      eventCoalescing: true,
    }),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([httpErrorInterceptor])),
    {
      provide: CSP_NONCE,
      useValue: myRandomNonceValue,
    },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    {
      provide: 'SocialAuthServiceConfig',
      useFactory: () => {
        return import('@abacritt/angularx-social-login').then(module => ({
          autoLogin: false,
          providers: [
            {
              id: module.GoogleLoginProvider.PROVIDER_ID,
              provider: new module.GoogleLoginProvider('321797914639-kognesknm35107f7jsrf213m3ikg2ftv.apps.googleusercontent.com')
            }
          ],
          onError: (err: any) => console.error(err)
        }));
      }
    }
  ]

};
