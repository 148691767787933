<p-sidebar (visibleChange)="generalService.onSidebarVisibleChange($event)" [blockScroll]="true"
           [fullScreen]="true"
           [visible]="generalService.sidebarVisible()" appendTo="body" role="region">
  <h3>Sidebar</h3>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
    magna aliqua.
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
  </p>

  <ng-template pTemplate="headless">
    <div class="flex flex-column h-full">
      <div class="flex align-items-center justify-content-between px-3 pt-2 flex-shrink-0">
                <span class="inline-flex align-items-center gap-2">
                    <span class="font-semibold text-2xl text-primary">
                        <img alt="logo" height="30" src="assets/images/logo-purple.png"></span>
                </span>
        <span>
                    <p-button (click)="closeCallback($event)" [rounded]="true" [text]="true" class="text-4xl"
                              icon="pi pi-times" styleClass="h-3rem w-3rem text-4xl" type="button"></p-button>
                </span>
      </div>
      <div class="overflow-y-auto">
        <app-sidebar-topbar-menu (onSidebarVisibleChange)="generalService.onSidebarVisibleChange($event)"
                                 [topMenuItems]="topMenuItems"></app-sidebar-topbar-menu>
      </div>

      @if (user()) {
        <div class="mt-auto">
          <hr class="mb-3 mx-3 border-top-1 border-none surface-border"/>
          <!-- <a pRipple
              class="m-3 flex align-items-center cursor-pointer p-3 gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">
              <p-avatar [image]="user()?.profilePhoto" shape="circle" />
              <span class="font-bold">{{ user()?.firstName }}</span>
          </a> -->

          <div class="flex flex-column w-full align-items-center gap-0">
            <button pButton
                    class="w-full dark-purple-bg-btn font-semibold p-3 text-center flex align-items-center justify-content-center m-auto border-noround">
              <span class="">Need Help</span>
              <img src="/assets/images/planet-purple.webp" class="absolute right-0 w-3rem pr-2"/>
            </button>
            <button pButton (click)="buyPackageSelected.emit()"
                    class="w-full buy-package-grad-btn font-semibold p-3 text-center flex align-items-center justify-content-center m-auto border-noround">
              <span class="">Buy Package</span>
              <img src="/assets/images/full-chevron-white-right.png" class="absolute right-0 w-2rem pr-2"/>
            </button>
          </div>

        </div>
      }
    </div>

  </ng-template>

</p-sidebar>
