import {
  Component,
  computed,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Injector,
  OnInit,
  Output,
  signal,
  ViewChild
} from '@angular/core';
import {Router} from '@angular/router';
import {IStopImpersonateStudentRequest} from '@GeneratedTsFiles/index';
import {MenuItem} from 'primeng/api';
import {ScrollPanel, ScrollPanelModule} from 'primeng/scrollpanel';
import {Sidebar, SidebarModule} from 'primeng/sidebar';
import {untilDestroyed} from 'src/app/core/helpers/until-destroyed';
import {ApiService} from 'src/app/core/services/api.service';
import {AuthService} from 'src/app/core/services/auth.service';
import {DataApiStateService, State} from 'src/app/core/services/data-api-state.service';
import {EmitEvent, EventBusService, Events} from 'src/app/core/services/event-bus.service';
import {GeneralService} from 'src/app/core/services/general.service';
import {IUserRole, TopMenuItem} from '../../models/general.model';
import {toObservable} from '@angular/core/rxjs-interop';
import {CommonModule, NgIf} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {AvatarModule} from 'primeng/avatar';
import {BadgeModule} from 'primeng/badge';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {MenuModule} from 'primeng/menu';
import {StyleClassModule} from 'primeng/styleclass';
import {NotificationItemCardComponent} from '../notifications/notification-item-card/notification-item-card.component';
import {
  FormFieldValidationMessageComponent
} from '../prime/form-field-validation-message/form-field-validation-message.component';
import {
  SidebarMainMenuListItemComponent
} from '../sidebar/sidebar-main-menu-list-item/sidebar-main-menu-list-item.component';
import {SidebarTopbarMenuComponent} from './sidebar-topbar-menu/sidebar-topbar-menu.component';
import {TopbarMenuItemsComponent, TopbarMenuType} from './topbar-menu-items/topbar-menu-items.component';
import {SplitButtonModule} from 'primeng/splitbutton';
import {TopbarSidebarComponent} from './topbar-sidebar/topbar-sidebar.component';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    InputTextModule,
    MenuModule,
    StyleClassModule,
    SidebarModule,
    ButtonModule,
    AvatarModule,
    BadgeModule,
    NgIf,
    SidebarMainMenuListItemComponent,
    ScrollPanelModule,
    SplitButtonModule,
    FormFieldValidationMessageComponent,
    SidebarTopbarMenuComponent,
    NotificationItemCardComponent,
    TopbarMenuItemsComponent,
    TopbarSidebarComponent,
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss',
})
export class TopbarComponent implements OnInit {
  @ViewChild('buyPackageMenu', {static: false}) buyPackageMenu: ElementRef | undefined;
  @ViewChild('scrollPanelNotifChild', {static: false}) scrollPanelNotifChild: ScrollPanel | undefined;
  @ViewChild('sidebarRef') sidebarRef!: Sidebar;
  @Output() hideSidebar = new EventEmitter<boolean>();
  readonly topBarMenuType = TopbarMenuType;
  authService = inject(AuthService);
  generalService = inject(GeneralService);
  apiService = inject(ApiService);
  topMenuItems: TopMenuItem[] = [];
  searchValue = '';
  items: MenuItem[] | undefined;
  buyPackageItemsVisible = signal(false);
  buyPackageLinks: MenuItem[] = [];
  isAuthenticatedComputed = computed(() => this.authService.userAuthenticated$());
  userRole = computed(() => this.authService.userDecodedJWTData$());
  IUserRole = IUserRole;
  userRole$ = IUserRole[this.userRole()?.role as keyof typeof IUserRole]; // Convert to IUserRole enum

  dashboardCounts$ = computed(() => this.dataStateService.getParentDashboard.state() || [] as State<any>[]);
  
  private readonly destroy: DestroyRef = inject(DestroyRef);
  private eventBusService = inject(EventBusService);
  private readonly dataStateService = inject(DataApiStateService);
  students$ = computed(() => {
    console.log(this.dataStateService.parentStudents.state());
    return this.dataStateService.parentStudents.state() || []
  });
  private readonly router = inject(Router);
  private untilDestroyed = untilDestroyed();
  private injector = inject(Injector);
  #userToSignal = this.authService.userDecodedJWTData$;
  user = computed(() => {
    return this.#userToSignal();
  });

  ngOnInit(): void {
    this.initTopbarItems();
    this.initEvents();
    this.prepareBuyPackageLinks();
  }

  toggleBuyPackageItems(): void {
    this.buyPackageItemsVisible.update(v => !v);
  }

  toggleSidebar(): void {
    this.hideSidebar.emit(true);
    this.generalService.sidebarVisible.set(true);
  }

  onSidebarVisibleChange(visible: boolean): void {
    this.generalService.sidebarVisible.set(visible);
  }

  onSelectListItem(event: { item: any, link: string }): void {
    if (event?.link) {
      this.router.navigate([event.link]);
    }
    this.onSidebarVisibleChange(false);
  }

  navigateTo(menuItem: TopMenuItem): void {
    const url = menuItem['link'];
    if (url) {
      this.router.navigate([url]);
      this.generalService.sidebarVisible.set(false);
    }
  }

  logout(): void {
    this.authService.logout();
    this.generalService.sidebarVisible.set(false);
  }

  goToHome(): void {
    this.authService.goToDashboard();
    this.generalService.sidebarVisible.set(false);
  }

  goToNotifications(): void {
    this.authService.goToNotificationsPerRole();
    this.generalService.sidebarVisible.set(false);
  }

  goToUserSettings(): void {
    this.authService.goToUserSettings();
    this.generalService.sidebarVisible.set(false);
  }

  goToFreeTrialForm(): void {
    this.generalService.sidebarVisible.set(false);
    this.router.navigate(['/request-new-trial/free-trial-reason']);
  }

  goToBuyPackage(): void {
    this.generalService.sidebarVisible.set(false);
    this.toggleBuyPackageItems();
    this.router.navigate(['/dashboard/buy-package/select-new-package']);
  }

  goToBuyPackageExtension(): void {
    this.generalService.sidebarVisible.set(false);
    this.toggleBuyPackageItems();
    this.router.navigate(['/dashboard/buy-package/select-package-extension']);
  }

  goToCartDetails(): void {
    this.generalService.sidebarVisible.set(false);
    this.generalService.cartSidebarVisible.set(true);
  }

  notifPanelRefresh(): void {
    this.scrollPanelNotifChild?.refresh();
  }

  stopImpersonation(): void {
    this.apiService.stopImpersonateStudent({
      studentRefreshToken: this.authService.getRefreshToken()
    }).pipe(this.untilDestroyed()).subscribe({
      next: (data: IStopImpersonateStudentRequest) => {
        this.authService.handleUserDataAndDecodeJWT(data);
        this.authService.goToDashboardPerRole();
      },
      error: (error) => console.error('Error stopping impersonation:', error)
    });
  }

  private prepareParentStudents(): void {
    const apiCall = this.apiService.getParentStudents({parentId: this.user()!.id});
    this.dataStateService.handleApiCall(apiCall, this.dataStateService.parentStudents.setState)
      .pipe(this.untilDestroyed())
      .subscribe({
        error: (err) => console.error('Error fetching parent students:', err)
      });
  }

  private initEvents(): void {
    // this event is fired when user is logged in
    const userLoggedInSubscription = toObservable(this.user, {
      injector: this.injector
    }).pipe(this.untilDestroyed()).subscribe({
      next: (user) => {
        if (!user) {
          return;
        }
        if (user?.role === IUserRole.PARENT) {
          this.eventBusService.emit(new EmitEvent(Events.StateLoadParentStudents, undefined));
          this.eventBusService.emit(new EmitEvent(Events.StateLoadParentDashboard, undefined));

          setTimeout(() => {
            this.eventBusService.emit(new EmitEvent(Events.StateLoadParentStudentsGroups, undefined));
          }, 10);
          // this.prepareParentStudents();
        }
      }
    });


    this.eventBusService.on(Events.ParentImpersonateStopped, () => {
      if (this.user()?.role === IUserRole.PARENT) {
        this.prepareParentStudents();
      }
    });

    this.eventBusService.on(Events.StudentRegistered, () => {
      if (this.user()?.role === IUserRole.PARENT) {
        this.prepareParentStudents();
      }
    });
  }

  private initTopbarItems(): void {
    this.topMenuItems = [
      {
        id: 'languages',
        text: 'Languages',
        position: 'left',
        hidden: false,
        submenu: [
          {id: 'features', text: 'Features'},
          {id: 'solutions', text: 'Solutions'},
          {id: 'customers', text: 'Customers'}
        ]
      },
      {
        id: 'about',
        text: 'About',
        position: 'left',
        submenu: [
          {id: 'aboutCustomers', text: 'Customers'}
        ]
      },
      {
        id: 'packagesMenu',
        text: 'packages',
        position: 'left',
        submenu: []
      },
      {
        id: 'signin',
        text: 'Log in',
        icon: 'sign-in-icon',
        position: 'right',
        class: 'login-link-top',
        link: '/auth/login',
        submenu: [
        ],
        guestOnly: true
      },
      {
        id: 'signup',
        text: 'Get started',
        icon: 'sign-in-icon',
        class: 'register-link-top',
        position: 'right',
        link: '/auth/register',
        submenu: [
        ],
        guestOnly: true
      },
      {
        id: 'profile',
        text: 'Profile',
        icon: 'profile-icon',
        link: '/dashboard/parent/overview',
        hidden: false,
        submenu: [
          {
            id: 'settings',
            text: 'Profile',
            link: this.authService.getRouteByRole()!,
            icon: 'pi-user',
            hidden: false,
            callback: () => {
              event?.preventDefault();
            }
          },
          {
            id: 'settings',
            text: 'Settings',
            link: '/dashboard/user-settings/account-info',
            icon: 'pi-cog',
            hidden: false,
            callback: () => {
              event?.preventDefault();
            }
          },
          {
            id: 'logout',
            text: 'Logout',
            icon: 'pi-sign-out',
            hidden: false,
            callback: () => {this.logout();
              
              event?.preventDefault();
            }
          }
        ]
      },
      {
        id: 'notifications',
        text: 'Notifications',
        icon: 'notification-icon',
        hidden: true,
      }
    ];
  }

  private prepareBuyPackageLinks(): void {
    this.buyPackageLinks = [
      {
        label: 'Update',
        command: () => {
        }
      },
      {
        label: 'Delete',
        command: () => {
        }
      },
      {label: 'Angular Website', url: 'http://angular.io'},
      {separator: true},
      {label: 'Upload', routerLink: ['/fileupload']}
    ];
  }
}
