import { Routes, mapToCanActivate } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { signInRoutes } from './core/routes/signin-routes';
import { freeTrialReasonRoutes, requestFreeTrialRoutes } from './core/routes/request-free-trial-routes';
import { userSettingsRoutes } from './core/routes/user-settings-routes';
import { dashboardRoutes } from './core/routes/dashboard-routes';
import { buyPackageRoutes } from './core/routes/buy-package';
import { pathRoutes, setPasswordFirstTimeRoutes } from './core/routes/path-routes.ts';

export const routes: Routes = [
    {
        path: '',
        canActivate: [], // Apply AuthGuard here
        children: [
            pathRoutes,
            setPasswordFirstTimeRoutes,
            dashboardRoutes,
            signInRoutes,
            freeTrialReasonRoutes,
            {
                path: 'register-student',
                loadComponent: () =>
                    import('./features/auth/register/register-free-trial-steps/student-registration-form/student-registration-form.component').then(
                        (m) => m.StudentRegistrationFormComponent
                    ),
            },
            {
                path: 'pages/register-student',
                loadComponent: () =>
                    import('./features/auth/register/register-student/register-student.component').then(
                        (m) => m.RegisterStudentComponent
                    ),
            },
            // Public routes
            {
                path: '', redirectTo: "/auth/register", pathMatch: "full"
            },
            // Always put the wild-card route last
            {
                path: '**', pathMatch: 'full',
                loadComponent: () =>
                    import('./shared/components/page-not-found/page-not-found.component').then(
                        (m) => m.PageNotFoundComponent
                    ),
            },
        ]
    },
    // Public routes (without AuthGuard)
    {
        path: '', redirectTo: "/auth/register", pathMatch: "full"
    },
];
