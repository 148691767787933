export const bookLessonRoutes = {
    path: 'book-lesson',
    canActivate: [],
    children: [
        {
            path: '',
            loadComponent: () =>
                import('../../features/dashboard/components/book-lesson-steps/book-lesson-steps.component').then(
                    (m) => m.BookLessonStepsComponent
                ),
            children: [
                {
                    path: 'choose-details',
                    loadComponent: () =>
                      import('../../features/dashboard/components/book-lesson-steps/book-lesson-choose-details/book-lesson-choose-details.component').then(
                            (m) => m.BookLessonChooseDetailsComponent
                        ),
                    children: [
                    ]
                },
                {
                    path: 'date-time',
                    loadComponent: () =>
                      import('../../features/dashboard/components/book-lesson-steps/book-lesson-weekly-day-chooser-calendar/book-lesson-weekly-day-chooser-calendar.component').then(
                            (m) => m.BookLessonWeeklyDayChooserCalendarComponent
                        ),
                    children: [
                    ]
                },
            ],
        },
    ],
};