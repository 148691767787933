import { AuthGuard } from '../guards/auth.guard'
import { CompletedRegistrationGuard } from '../guards/completed-registration.guard.ts';
import { signedInGuard } from '../guards/signed-in.guard';

export const freeTrialReasonRoutes = {
    path: 'request-new-trial',
    loadComponent: () =>
        import('../../features/auth/auth.component').then(
            (m) => m.AuthComponent,
        ),
    canActivate: [CompletedRegistrationGuard,],
    children: [

    ]

};

export const requestFreeTrialRoutes = {
    path: 'request-free-trial',

    canActivate: [CompletedRegistrationGuard],
    children: [
        {
            data: {
                isAnotherFreeTrialPage: false,
            },
            path: '',
            loadComponent: () =>
                import('../../features/auth/register/register-free-trial-steps/register-free-trial-steps.component').then(
                    (m) => m.RegisterFreeTrialStepsComponent
                ),
            loadOptions: { defer: true },
            children: [
                {
                    path: 'reason',
                    children: [
                        {
                            data: {
                                isAnotherFreeTrialPage: true,
                            },
                            path: 'free-trial-reason',
                            loadComponent: () =>
                                import('../../features/auth/register/register-free-trial-steps/request-trial-reason-for-request-step/request-trial-reason-for-request-step.component').then(
                                    (m) => m.RequestTrialReasonForRequestStepComponent
                                ),
                        },
                        {
                            path: 'free-trial-thank-you',
                            loadComponent: () =>
                                import('../../features/auth/register/register-free-trial-steps/trial-request-thank-you/trial-request-thank-you.component').then(
                                    (m) => m.TrialRequestThankYouComponent
                                ),
                        },
                        {
                            data: {
                                isAnotherFreeTrialPage: true,
                            },
                            path: 'trial-request-choose-student',
                            loadComponent: () =>
                                import('../../features/auth/register/register-free-trial-steps/trial-request-choose-student-step/trial-request-choose-student-step.component').then(
                                    (m) => m.TrialRequestChooseStudentStepComponent
                                ),
                        },
                        {
                            path: 'trial-request-choose-language',
                            loadComponent: () =>
                                import('../../features/auth/register/register-free-trial-steps/trial-request-choose-language-step/trial-request-choose-language-step.component').then(
                                    (m) => m.TrialRequestChooseLanguageStepComponent
                                ),
                        },
                        {
                            path: 'trial-request-add-new-language',
                            loadComponent: () =>
                                import('../../features/auth/register/register-free-trial-steps/trial-request-add-new-language-step/trial-request-add-new-language-step.component').then(
                                    (m) => m.TrialRequestAddNewLanguageStepComponent
                                ),
                        },
                    ]

                },

                {
                    path: 'student-name',
                    loadComponent: () =>
                        import('../../features/auth/register/register-free-trial-steps/student-registration-form/student-name-step-form/student-name-step-form.component').then(
                            (m) => m.StudentNameStepFormComponent
                        ),
                    canActivate: [AuthGuard],
                },
                {
                    path: 'student-info',
                    loadComponent: () =>
                        import('../../features/auth/register/register-free-trial-steps/student-registration-form/student-info-step-form/student-info-step-form.component').then(
                            (m) => m.StudentInfoStepFormComponent
                        ),
                    canActivate: [AuthGuard],
                },
                {
                    path: 'student-availability',
                    loadComponent: () =>
                        import('../../features/auth/register/register-free-trial-steps/student-registration-form/student-availability-step-form/student-availability-step-form.component').then(
                            (m) => m.StudentAvailabilityStepFormComponent
                        ),
                    canActivate: [AuthGuard],
                },
                {
                    path: 'student-more-details',
                    loadComponent: () =>
                        import('../../features/auth/register/register-free-trial-steps/student-registration-form/student-more-details-step-form/student-more-details-step-form.component').then(
                            (m) => m.StudentMoreDetailsStepFormComponent
                        ),
                    canActivate: [AuthGuard],
                },
                {
                    path: 'student-set-password',
                    loadComponent: () =>
                        import('../../features/auth/register/register-free-trial-steps/student-registration-form/student-set-password-step-form/student-set-password-step-form.component').then(
                            (m) => m.StudentSetPasswordStepFormComponent
                        ),
                    canActivate: [AuthGuard],
                },
                {
                    path: 'social-auth',
                    loadComponent: () =>
                        import('../../features/auth/after-social-auth-form/after-social-auth-form.component').then(
                            (m) => m.AfterSocialAuthFormComponent
                        ),
                },
                {
                    path: 'register-success',
                    loadComponent: () =>
                        import('../../features/auth/register/register-free-trial-steps/register-success/register-success.component').then(
                            (m) => m.RegisterSuccessComponent
                        ),
                },
            ]
        },
    ]
};