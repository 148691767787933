import {AuthGuard} from "../guards/auth.guard";
import {signedInGuard} from "../guards/signed-in.guard";

export const signInRoutes =  {
  path: 'signin',
  canActivate: [signedInGuard],
  loadComponent: () =>
      import('../../features/auth/auth.component').then(
          (m) => m.AuthComponent
      ),
  children: [
      // TODO: check if new component is needed for forgot password
      // {
      //     path: 'forgot-password',
      //     loadComponent: () =>
      //         import('./features/auth/forgot-password/forgot-password.component').then(
      //             (m) => m.ForgotPasswordComponent
      //         ),
      // },
      // {
      //     path: 'set-password-first-time',
      //     loadComponent: () =>
      //         import('./features/auth/set-password-first-time/set-password-first-time.component').then(
      //             (m) => m.SetPasswordFirstTimeComponent
      //         ),
      // },
      {
          path: ':mode',
          loadComponent: () =>
              import('../../features/auth/login/login.component').then(
                  (m) => m.LoginComponent
              ),
      },
  ]
};
