import { inject } from "@angular/core";
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChildFn, CanActivate, UrlTree } from "@angular/router";
import { map, catchError, Observable, take } from "rxjs";
import { AuthService } from "../services/auth.service";
import { GeneralService } from "../services/general.service";

export const AuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
):
  Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree => {

  const authService = inject(AuthService);
  const generalService = inject(GeneralService);
  const isAuth = authService.isAuth();

  // if never has set password one time dont lock it 
  // if (authService.getHasSetPasswordOneTime()?.setPasswordOneTime) {
  //   return inject(Router).createUrlTree(['/set-password-first-time']);
  // }


  if (isAuth && state.url === '/request-free-trial') {
    generalService.navigateToParentDashboard();
    return true;
  }

  return isAuth
    ? true
    : inject(Router).createUrlTree(['/auth/login']);

};
