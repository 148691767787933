import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  type OnInit,
  Output
} from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {
  FormFieldValidationMessageComponent
} from '../../prime/form-field-validation-message/form-field-validation-message.component';
import {
  SidebarMainMenuListItemComponent
} from '../../sidebar/sidebar-main-menu-list-item/sidebar-main-menu-list-item.component';
import {DataApiStateService} from '../../../../core/services/data-api-state.service';
import {GeneralService} from '../../../../core/services/general.service';
import {AuthService} from '../../../../core/services/auth.service';
import {IUserRole, TopMenuItem} from 'src/app/shared/models/general.model';
import {Router, RouterModule} from '@angular/router';
import {Severity} from 'src/app/core/models/severity';
import {
  StudentGroupSelectionDialogComponent
} from '../../dialogs/student-group-selection-dialog/student-group-selection-dialog.component';
import {IStudentGroupDto} from "@GeneratedTsFiles/StudentGroups/Dtos/IStudentGroupDto";
import { MainDashboardSidebarComponent } from '../../sidebar/main-dashboard-sidebar/main-dashboard-sidebar.component';

@Component({
  selector: 'app-sidebar-topbar-menu',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ButtonModule,
    ScrollPanelModule,
    FormFieldValidationMessageComponent,
    SidebarMainMenuListItemComponent,
    MainDashboardSidebarComponent,
  ],
  templateUrl: './sidebar-topbar-menu.component.html',
  styleUrl: './sidebar-topbar-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarTopbarMenuComponent implements OnInit {

  generalService = inject(GeneralService);
  dataApiStateService = inject(DataApiStateService);
  authService = inject(AuthService);
  router = inject(Router);
  IUserRole = IUserRole;
  Severity = Severity;

  topMenuItems = input<TopMenuItem[]>([]);
  userRole = computed(() => {
    return (this.authService.userDecodedJWTData$());
  });
  students$ = computed(() => {
    return this.dataApiStateService.parentStudents.state() || [];
  });
  studentGroups$ = computed(() => {
    return this.dataApiStateService.parentStudentsGroups.state() || [];
  });
  @Output() onSidebarVisibleChange = new EventEmitter<boolean>();
  topButtonMenuItems = [
    {
      id: 'signin',
      text: 'Log in',
      icon: 'sign-in-icon',
      position: 'right',
      class: 'login-link-top',
      link: '/auth/login',
      submenu: [
      ]
    },
    {
      id: 'signup',
      text: 'Sign Up',
      icon: 'sign-in-icon',
      class: 'register-link-top',
      position: 'right',
      link: '/auth/register',
      submenu: [
      ]
    }
  ];
  #userToSignal = this.authService.userDecodedJWTData$;
  user = computed(() => {
    return this.#userToSignal();
  });

  ngOnInit(): void {
  }

  onSelectListItem($event: { item: any, link: string }) {
    console.log($event);
    if ($event && $event.link) {
      this.router.navigate([$event.link]);
    }
    this.onSidebarVisibleChange.emit(false);
  }

  goToHome() {
    this.authService.goToDashboard();
    this.onSidebarVisibleChange.emit(false);
  }

  goToUserSettings() {
    this.authService.goToUserSettings();
    this.onSidebarVisibleChange.emit(false);
  }

  goToLogout() {
    this.authService.logout();
    this.onSidebarVisibleChange.emit(false);
  }

  navigateTo(menuItem: any) {
    const url = menuItem['link'];
    if (!url) {
      return;
    }
    this.router.navigate([url]);
    this.onSidebarVisibleChange.emit(false);
  }

  onSelectListGroupItem($event: { item: IStudentGroupDto, link: string }) {
    if ($event) {
      console.log($event.item)
      const params = {
        'editMode': true,
        'studentGroupItem': $event.item,
      }
      this.generalService.openComponent(StudentGroupSelectionDialogComponent, params);
    }
  }

  openNewGroupDialog() {
    // if (this.students$().students.length <= 1) {
    //     return;
    // }
    const params = {
      'editMode': false
    }
    this.generalService.openComponent(StudentGroupSelectionDialogComponent, params);
  }

}
