import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, HostListener, inject, Input, signal} from '@angular/core';
import {Router, RouterModule} from '@angular/router';
import {StyleClassModule} from 'primeng/styleclass';
import {AuthService} from 'src/app/core/services/auth.service';
import {IUserRole, TopMenuItem} from 'src/app/shared/models/general.model';

export enum TopbarMenuType {
  menu = 'menu',
  buyPackageDropdown = 'buyPackageDropdown',
  userDropdown = 'userDropdown',
  buyPackageExtensionDropdown = 'buyPackageExtensionDropdown',
}

@Component({
  selector: 'app-topbar-menu-items',
  standalone: true,
  imports: [
    CommonModule,
    StyleClassModule,
    RouterModule,
  ],
  templateUrl: './topbar-menu-items.component.html',
  styleUrl: './topbar-menu-items.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopbarMenuItemsComponent {
  authService = inject(AuthService);
  router = inject(Router);
  menuVisibility: { [key: string]: boolean } = {
    languages: false,
    about: false,
    profile: false,
    signin: false,
    packagesMenu: false
  };
  isAuthenticatedComputed = computed(() => {
    console.log(this.authService.userAuthenticated$());
    return (this.authService.userDecodedJWTData$());
  });
  readonly menuType = TopbarMenuType;
  @Input() topMenuItems: any[] = [];
  @Input() showItemType = this.menuType.menu as TopbarMenuType;
  isMenuVisible = false;
  sidebarVisible = signal(false);
  buyPackageItemsVisible = signal(false);
  IUserRole = IUserRole;
  userRole = computed(() => {
    return (this.authService.userDecodedJWTData$());
  });
  #userToSignal = this.authService.userDecodedJWTData$;
  user = computed(() => {
    return this.#userToSignal();
  });

  @HostListener('mouseover')
  onMouseEnter(menuItemId: keyof typeof this.menuVisibility) {
    // console.log(menu);
    // if (!menu) {
    //     return;
    // }

    if (!menuItemId) {
      return;
    }
    this.menuVisibility[menuItemId] = true;
  }

  @HostListener('mouseleave')
  onMouseLeave(menuItemId: keyof typeof this.menuVisibility) {
    console.log(menuItemId);
    if (!menuItemId) {
      return;
    }
    this.menuVisibility[menuItemId] = false;
    this.updateMenuVisibility();
    // console.log('onMouseLeave',this.menuVisibility);
  }


  navigateTo(menuItem: TopMenuItem | any) {
    const url = menuItem['link'];
    if (!url) {
      return;
    }
    this.menuVisibility['profile'] = false;
    this.router.navigate([url]);
    this.sidebarVisible.set(false);
  }

  updateMenuVisibility(): void {
    const isLoggedIn = this.isAuthenticatedComputed(); // Assuming this function checks if the user is logged in
    console.log('updateMenuVisibility', isLoggedIn);
    this.topMenuItems.forEach(item => {
      if (item.id === 'profile' && isLoggedIn) {
        item.hidden = true;
        item.submenu!.forEach((subItem: any) => {
          subItem.hidden = true;
        });
      } else if (item.id === 'signin' && !isLoggedIn) {
        item.hidden = false;
        item.submenu!.forEach((subItem: any) => {
          subItem.hidden = false;
        });
      } else {
        item.hidden = true;
        if (item.submenu) {
          item.submenu!.forEach((subItem: any) => {
            subItem.hidden = true;
          });
        }

      }
    });
  }


  goToBuyPackage() {
    this.sidebarVisible.set(false);
    this.toggleBuyPackageItems();
    this.router.navigate(['/dashboard/buy-package/select-new-package']);
  }

  goToBuyPackageExtension() {
    this.sidebarVisible.set(false);
    this.toggleBuyPackageItems();
    this.router.navigate(['/dashboard/buy-package/select-package-extension']);
  }

  toggleBuyPackageItems() {
    this.isMenuVisible = !this.isMenuVisible;

    // const menuElement = this.buyPackageMenu!.nativeElement;
    // if (menuElement.classList.contains('hidden')) {
    //   menuElement.classList.remove('hidden');
    //   menuElement.classList.add('scalein');
    // } else {
    //   menuElement.classList.remove('scalein');
    //   menuElement.classList.add('fadeout');
    //   setTimeout(() => {
    //     menuElement.classList.add('hidden');
    //     menuElement.classList.remove('fadeout');
    //   }, 150); // Transition duration
    // }

    this.buyPackageItemsVisible.set(!this.buyPackageItemsVisible());
  }


  toggleDropdown(event: MouseEvent, type = 'profile'): void {
    this.menuVisibility['profile'] = true;
  }

}
