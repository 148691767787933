@if (user()) {

  @defer {
    <app-main-dashboard-sidebar extraLayoutCssClass="full_shown" [addLogoutLink]="true"></app-main-dashboard-sidebar>

  }
  
} @else {

<div class="mx-auto row grid align-items-center justify-content-center text-center w-full">
  <div class="mx-auto mt-5 sm:w-24rem w-full text-center">
    <div
      class="px-3 mb-2 flex align-items-center cursor-pointer gap-2 border-round text-700 hover:surface-100 transition-duration-150 transition-colors p-ripple">

      <span class="font-bold text-xl">Menu</span>
  </div>
    <div class="flex flex-column align-items-center justify-content-center gap-3 px-3">
      @for (menuItem of topButtonMenuItems; track menuItem.text) {
      <a pRipple data-closeSidebar class="w-full px-3 lg:px-3 py-3 align-items-center hover:opacity-50 font-medium transition-colors
        transition-duration-150 menu-item {{ menuItem.class }}" [routerLink]="[menuItem.link]">
        <span class="text-xl">{{ menuItem.text }}</span>
      </a>
      }
    </div>

  </div>
</div>

}