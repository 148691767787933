import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  computed,
  ElementRef,
  HostListener,
  inject,
  Injector,
  NgZone,
  Renderer2,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { TopbarComponent } from './shared/components/topbar/topbar.component';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { ToastComponent } from './shared/components/prime/toast/toast.component';
import { GeneralService } from './core/services/general.service';
import { ToastService } from './core/services/toast.service';
import { distinctUntilChanged, filter, interval, map, startWith, take, takeUntil, tap } from 'rxjs';
import { ToastModule } from 'primeng/toast';
import { StateApiCallsComponent } from './shared/components/state-api-calls/state-api-calls.component';
import { CartSidebarComponent } from './shared/components/cart-sidebar/cart-sidebar.component';


const components = [TopbarComponent, ToastComponent, StateApiCallsComponent, CartSidebarComponent];

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ButtonModule,
    ToastModule,
    components
  ],
  providers: [],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  router = inject(Router);
  renderer = inject(Renderer2);
  title = 'LG.FE.ELS';
  sidebarVisible = false;
  generalService = inject(GeneralService);
  @ViewChild('dynamicComponentContainer', {
    read: ViewContainerRef,
    static: true
  }) dynamicComponentContainer: ViewContainerRef | undefined;
  _stable = false;
  loader = computed(() => {
    return this.generalService.routerLoading();
  });
  private primeConfig = inject(PrimeNGConfig);
  private navigationStartTime: number = 0;
  private _zone: NgZone = inject(NgZone);

  constructor(private message: MessageService, private toastService: ToastService,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.generalService.setDevice();
  }

  ngOnInit() {
    this.primeConfig.ripple = true;
    this.generalService.openDialog$.subscribe(object => {
      if (!object) {
        return;
      }
      console.log(object);
      // Logic to handle opening the dialog with the received parameters
      this.loadDialogComponent(object.component, object.parameters);
    });
    this.generalService.setDevice();
    this.listenForClicks();


    const navigationStart$ = this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      map(() => {
        this.navigationStartTime = Date.now();
      })
    );

    this._zone.runOutsideAngular(() => {
      // Check very regularly to see if the pending macrotasks have all cleared
      interval(10)
        .pipe(
          startWith(0), // So that we don't initially wait
          // To prevent a memory leak on two closely times route changes, take until the next nav start
          takeUntil(navigationStart$),
          // Turn the interval number into the current state of the zone
          map(() => !this._zone.hasPendingMacrotasks),
          // Don't emit until the zone state actually flips from `false` to `true`
          distinctUntilChanged(),
          // Filter out unstable event. Only emit once the state is stable again
          filter(stateStable => stateStable === true),
          // Complete the observable after it emits the first result
          take(1),
          tap(stateStable => {
            // FULLY RENDERED!!!!
            // Add code here to report Fully Rendered
            console.log('Fully Rendered');
          })
        ).subscribe();
    });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        console.log('NavigationStart', event);
        this.navigationStartTime = Date.now();

        this.generalService.routerLoading.set(true);
      } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {

        this.generalService.onSidebarVisibleChange(false);
        setTimeout(() => {
          this.generalService.setErrorDataSignal('');
          this.generalService.routerLoading.set(false);
        }, 100);
        const elapsedTime = Date.now() - this.navigationStartTime;
        // console.log(`Navigation took ${elapsedTime} ms`);
        this.renderer.listen('window', 'load', () => {
        });
        // console.log('NavigationEnd', event);
      }
    });
  }

  onShowSidebar(showSidebar: boolean) {
    this.sidebarVisible = showSidebar;
  }

  loadDialogComponent(
    component?: any,
    parameters?: any
  ): ComponentRef<any> {
    // Clear previous dynamic component, if any
    this.dynamicComponentContainer!.clear();

    // Resolve the component factory for the provided component type
    // Create the component and attach it to the view
    const componentRef = this.dynamicComponentContainer!.createComponent(component, {
      injector: Injector.create({
        providers: [
          { provide: 'dialogParameters', useValue: parameters }
        ]
      })
    });

    // Optionally, you can return the component reference or any other data
    return componentRef;
  }


  private listenForClicks() {

    this.renderer.listen('document', 'click', (event) => {
      const target = event.target as HTMLElement;
      console.log(target);

      // Check if the target has the data-closeSidebar attribute
      if (target.hasAttribute('data-closeSidebar')) {
        this.generalService.onSidebarVisibleChange(false);
      }

      if (target.tagName === 'BUTTON') {
        // this.generalService.setErrorDataSignal('');
      }
    });
  }
}
