import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {FooterComponent} from '../footer/footer.component';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    FooterComponent,
  ],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {

  ngOnInit() {

  }

  goBack() {
    history.back();
  }
}
