<div #profileDiv class="flex justify-content-between align-items-center px-2 md:px-3 surface-0 border-bottom-1 surface-border
  fixed w-full main-header-wrap" style="height: 80px;">
  <div class="flex">
    <div class="flex align-items-center lg:px-0 flex-shrink-0 mr-2">
      <a (click)="authService.goToDashboardPerRole()" class="flex align-items-center cursor-pointer">
        <img alt="logo" height="54" src="assets/images/lingogalaxy-logo.svg">
      </a>
      <h2 class="text-purple-700 text-lg"></h2>
    </div>
    @if (authService.userDecodedJWTData$()) {
      @if (userRole()?.role === IUserRole.PARENT) {
        <!-- <ul class="list-none p-0 m-0 hidden xl:flex align-items-center select-none flex-row surface-section">
          <app-topbar-menu-items [topMenuItems]="topMenuItems"
                                 [showItemType]="topBarMenuType.buyPackageDropdown"></app-topbar-menu-items>
        </ul> -->
      }
    }
  </div>

  <div class="flex align-items-center">
    <ul class="list-none p-0 m-0 flex align-items-center select-none flex-row">
      @if (authService.isAuth()) {

        @if (userRole()?.role === IUserRole.PARENT) {
          <li class="block">
            <a pRipple (click)="goToCartDetails()"
               class="p-ripple p-element cart__badge flex align-items-center text-600 hover:text-900 hover:opacity-50 font-medium border-round cursor-pointer transition-duration-150 transition-colors">
              <div
                class="cart__badge _icon relative p-1 flex-shrink-0 border-circle bg-cyan-500 cursor-pointer border-2 surface-border transition-all transition-duration-300">
                <img src="assets/images/icons/online-shopping.svg" class="invert" alt="shopping-cart"
                     height="24">
                @if (dashboardCounts$()?.data) {
                <span id="pr_id_1_badge" class="p-badge primary-purple-color _badge">{{dashboardCounts$()?.data.numberOfItemsInBasket}}</span> 
                }
              </div>

            </a>

          </li>
        }
        <li class="block">
          <ng-container *ngTemplateOutlet="notificationTemplate"></ng-container>
        </li>
      }
    </ul>

    <ul class="list-none p-0 m-0 flex align-items-center select-none flex-row surface-section">
      @if (!authService.isAuth()) {
        <app-topbar-menu-items [topMenuItems]="topMenuItems"></app-topbar-menu-items>
      } @else {
        <li class="hidden md:block" *ngIf="userRole()?.role === IUserRole.PARENT">
          
        </li>
        <!-- <li class="hidden md:block mr-2">
          <p-button label="My Profile" styleClass="primary-purple-color" (click)="goToHome()" size="small"
                    [outlined]="true" [rounded]="true"></p-button>
        </li> -->

        <li class="hidden md:block">
          @if (user()) {

            @if (user()!.isImpersonated === true) {
              <a pRipple class="inline-flex px-0 py-3 align-items-center hover:text-blue-600 font-medium transition-colors
        transition-duration-150 menu-item justify-content-end" (click)="stopImpersonation()">
                <div class="avatar-circle smaller">
                  <img src="assets/images/dummy-image.png" class="border-round">
                </div>
                <div class="hidden lg:block lg:ml-2">
                  <!-- <div class="text-900 font-medium">Hi {{ user()?.originalUserFirstName }}</div> -->
                  <span class="text-600 font-medium text-sm w-15rem">Parent profile</span>
                </div>
              </a>
              <!-- <p-button label="Stop" styleClass="text-white" (click)="stopImpersonation()"></p-button> -->
            }
          }
        </li>
        <!-- dynamic-menu.component.html -->
        <app-topbar-menu-items [topMenuItems]="topMenuItems"
                               [showItemType]="topBarMenuType.userDropdown"></app-topbar-menu-items>
      }

    </ul>

    <a (click)="toggleSidebar()" class="p-ripple p-element cursor-pointer inline-flex align-items-center justify-content-center xl:hidden text-700 mr-2" enterFromClass="hidden" enteractiveclass="fadeinleft"
       leaveactiveclass="fadeoutleft" leavetoclass="hidden" pRipple
       pstyleclass="#sidebar">
      <i class="pi pi-bars text-4xl burger-bars"></i>
    </a>
  </div>
</div>

<app-topbar-sidebar (buyPackageSelected)="goToBuyPackage()" [topMenuItems]="topMenuItems"></app-topbar-sidebar>

<ng-template #notificationTemplate>
  <div class="sm:relative">
    <a (click)="notifPanelRefresh()" [hideOnOutsideClick]="true" class="p-ripple p-element flex p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-duration-150 transition-colors" enterActiveClass="scalein"
       enterClass="hidden" leaveActiveClass="fadeout" leaveToClass="hidden"
       pRipple
       pStyleClass="@next">
      <!-- <img alt="shopping-cart" class="" height="24" src="assets/images/icons/notif-icon.svg"> -->

      <i
          severity="danger" class="p-element pi pi-bell text-2xl p-overlay-badge"><span
              id="pr_id_1_badge" class="p-badge p-component p-badge-danger p-badge-dot notif__badge"></span></i>
    </a>

    <div class="hidden absolute left-0 w-full sm:w-auto sm:left-auto sm:top-auto sm:right-0 shadow-2 origin-top surface-overlay border-round p-3 mt-1 sm:w-25rem"
         id="popover-cart">
      <div class="text-900 text-xl pb-3 border-bottom-1 surface-border">Your Notifications</div>

      <p-scrollPanel #scrollPanelNotifChild [style]="{ height: '270px'  }"
                     styleClass="customsidebar-scroll ">
        <app-notification-item-card></app-notification-item-card>
        <app-notification-item-card [isRead]="true"></app-notification-item-card>
        <app-notification-item-card></app-notification-item-card>
        <app-notification-item-card></app-notification-item-card>
        <app-notification-item-card></app-notification-item-card>
      </p-scrollPanel>

      <ul class="list-none p-0 m-0">
        <li class="py-3 flex align-items-center p-fluid">
          <button (click)="goToNotifications()" class="p-button-outlined mr-2" label="View notifications" leaveActiveClass="fadeout"
                  leaveToClass="hidden" pButton pRipple
                  pStyleClass="#popover-cart"></button>
        </li>
      </ul>
    </div>
  </div>
</ng-template>
