import { AuthGuard } from "../guards/auth.guard";
import { signedInGuard } from "../guards/signed-in.guard";

export const signInRoutes = {
    path: 'auth',
    canActivate: [signedInGuard],
    loadComponent: () =>
        import('../../features/auth/auth.component').then(
            (m) => m.AuthComponent
        ),
    children: [
        {
            path: '',
            loadComponent: () =>
                import('../../features/auth/login/login.component').then(
                    (m) => m.LoginComponent
                ),
        },
        {
            path: 'register',
            loadComponent: () =>
                import('../../features/auth/register/register-free-trial-steps/registration-choose-step/registration-choose-step.component').then(
                    (m) => m.RegistrationChooseStepComponent
                ),
        },
        {
            path: 'register-parent',
            loadComponent: () =>
                import('../../features/auth/register/register-free-trial-steps/parent-registration-form/parent-registration-form.component').then(
                    (m) => m.ParentRegistrationFormComponent
                ),
        },
        {
            path: 'register-student',
            loadComponent: () =>
                import('../../features/auth/register/register-free-trial-steps/student-registration-form/student-registration-form.component').then(
                    (m) => m.StudentRegistrationFormComponent
                ),
        },
        {
            path: 'forgot-password',
            loadComponent: () =>
                import('../../features/auth/forgot-password/forgot-password.component').then(
                    (m) => m.ForgotPasswordComponent
                ),
        },
        {
            path: 'register-otp',
            loadComponent: () =>
                import('../../features/auth/register/register-otp/register-otp.component').then(
                    (m) => m.RegisterOtpComponent
                ),
        },
        {
            path: 'login',
            loadComponent: () =>
                import('../../features/auth/login/login.component').then(
                    (m) => m.LoginComponent
                ),
        },
    ]
};
