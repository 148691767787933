import {AuthGuard} from "../guards/auth.guard";

export const pathRoutes =  {
  path: 'complete-registration',
  canActivate: [AuthGuard],
  
  loadComponent: () =>
    import('../../features/auth/after-social-auth-form/after-social-auth-form.component').then(
      (m) => m.AfterSocialAuthFormComponent
  ),
};


export const setPasswordFirstTimeRoutes =  {
  path: 'set-password-first-time',
  canActivate: [AuthGuard],
  
  loadComponent: () =>
    import('../../features/auth/set-password-first-time/set-password-first-time.component').then(
      (m) => m.SetPasswordFirstTimeComponent
  ),
};
