import { ChangeDetectorRef, ErrorHandler, Injectable, inject } from '@angular/core';
import { ToastService } from '../services/toast.service';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {
  constructor(private toastService: ToastService) { }
  handleError(error: any): void {
    // Log the error to the console.
    console.error(error);

    // setTimeout(() => {
    //   // Display a friendly error message to the user.
    //   this.toastService.show({
    //     summary: 'Operation Failed!',
    //     detail: 'Something went wrong.',
    //     severity: 'error'
    //   });
    // }, 100);
  }
}