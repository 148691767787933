
<div class="min-h-screen flex flex-column relative flex-auto">
    @if (loader()){
        <div class="progress-bar"></div>
        <div  class="ngx-spinner">
            <div [style.width]="'20px'" [style.height]="'20px'" class="ngx-spinner-icon"></div>
          </div>
    }

    <app-topbar />
    <div class="flex flex-column flex-auto justify-content-start">
        <div class="main-wrapper">
            <router-outlet />
        </div>
    </div>
    <app-cart-sidebar [inSidebar]="true" />

</div>

@defer (on viewport) {
<app-toast />
} @placeholder {
<span>loading</span>
}
<app-state-api-calls />

<div #dynamicComponentContainer></div>
