import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, DestroyRef, inject, type OnInit} from '@angular/core';
import {Observable, Subscription, filter, fromEvent, mapTo, merge, of, pairwise, startWith, switchMap} from 'rxjs';
import {untilDestroyed} from 'src/app/core/helpers/until-destroyed';
import {ApiService} from 'src/app/core/services/api.service';
import {DataApiStateService} from '../../../core/services/data-api-state.service';
import {EventBusService, Events} from 'src/app/core/services/event-bus.service';
import {AuthService} from 'src/app/core/services/auth.service';
import {GeneralService} from 'src/app/core/services/general.service';
import {ImpersonateStudentRequest} from '@GeneratedTsFiles/Identity/ImpersonateStudentRequest';
import {ToastService} from 'src/app/core/services/toast.service';
import {ToastMessages, getToastMessage} from '../../models/toast-messages';
import {
  IBasket,
  IGetBasketResponse,
  IGetCountriesResponse,
  IGetStudentGroupResponse,
  IParents,
  IpGeolocation,
  IpGeoRequest,
  IpGeoResponse,
  LocationDataRoutes,
  StudentGroupRoutes
} from '@GeneratedTsFiles/index';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DeviceKind} from '../../models/general.model';

@Component({
  selector: 'app-state-api-calls',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './state-api-calls.component.html',
  styleUrl: './state-api-calls.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateApiCallsComponent implements OnInit {
  private apiService = inject(ApiService);
  private generalService = inject(GeneralService);
  private dataStateService = inject(DataApiStateService);
  private eventBusService = inject(EventBusService);
  private authService = inject(AuthService);
  private toastService = inject(ToastService);
  private subscriptions: Subscription[] = [];
  private untilDestroyed = untilDestroyed();
  private previousOnline = navigator.onLine;
  private readonly destroy: DestroyRef = inject(DestroyRef);
  #userToSignal = this.authService.userDecodedJWTData$;
  online$: Subscription = {} as Subscription;

  user = computed(() => {
    console.log(this.#userToSignal());
    return this.#userToSignal();
  });

  ngOnInit(): void {
    this.initEvents();
    this.checkNavigatorStatus();
    this.handleDevice();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private initEvents() {
    const eventMap = [
      {event: Events.StateLoadTeachingLanguages, handler: () => this.loadLanguages()},
      {event: Events.StateLoadParentStudents, handler: () => this.loadParentStudents()},
      {event: Events.StateLoadParentStudentsGroups, handler: () => this.loadParentStudentsGroups()},
      {event: Events.StateLoadStartImpersonate, handler: (payload: any) => this.loadStartImpersonate(payload)},
      {event: Events.StudentGroupAdded, handler: () => this.loadParentStudentsGroups()},
      {event: Events.StudentGroupEdited, handler: () => this.loadParentStudentsGroups()},
      {event: Events.StateLoadGetBasket, handler: () => this.loadGetBacket()},
      {event: Events.StateLoadGeoLocationData, handler: () => this.loadGeoLocationData()},
      {event: Events.StateLoadCountries, handler: () => this.loadCountries()},
    ];

    eventMap.forEach(({event, handler}) => {
      const subscription = this.eventBusService.on(event, handler);
      this.subscriptions.push(subscription);
    });
  }

  private loadLanguages() {
    this.handleApiCall(this.apiService.getTeachingLanguages(), this.dataStateService.teachingLanguages.setState);
  }

  private loadParentStudents() {
    const parentId = this.user()!.id;
    this.handleApiCall(this.apiService.getApiData<any>({
      url: IParents.getStudents,
      method: 'GET'
    }, {ParentId: parentId}), this.dataStateService.parentStudents.setState);
  }

  private loadParentStudentsGroups() {
    const parentId = this.user()?.id;
    this.handleApiCall(this.apiService.getApiData<IGetStudentGroupResponse>({
      url: StudentGroupRoutes.getAllForParent,
      method: 'GET'
    }, {parentId}), this.dataStateService.parentStudentsGroups.setState);
  }

  private loadStartImpersonate(payload: ImpersonateStudentRequest) {
    const apiCall = this.apiService.impersonateStudent({
      impersonateStudentId: payload.impersonateStudentId,
      parentRefreshToken: this.authService.getRefreshToken()
    });

    this.handleApiCall(apiCall, this.dataStateService.startImpersonateStudent.setState, response => {
      this.authService.handleUserDataAndDecodeJWT(response);
      this.generalService.navigateToYoungsterDashboard();
      this.toastService.show(getToastMessage(ToastMessages.ParentImpersonateStarted.success, {data: this.authService.getUserDecodedData()!.firstName}));
    });
  }

  private loadGetBacket() {
    const parentId = this.authService.getUser()?.id;
    this.handleApiCall(this.apiService.getApiData<IGetBasketResponse>({
      url: IBasket.getBasket,
      method: 'GET'
    }, {parentId}), this.dataStateService.getBasket.setState);
  }

  private loadCountries() {
    const parentId = this.authService.getUser()?.id;
    this.handleApiCall(this.apiService.getApiData<IGetCountriesResponse>({
      url: LocationDataRoutes.getCountries,
      method: 'GET'
    }), this.dataStateService.getCountries.setState);
  }

  private loadGeoLocationData() {

    const geoLocationData = {
      "status": true,
      "message": false,
      "continent": false,
      "continentCode": false,
      "country": true,
      "countryCode": true,
      "region": true,
      "regionName": true,
      "city": true,
      "district": true,
      "zip": false,
      "lat": false,
      "lon": false,
      "timezone": true,
      "offset": true,
      "currency": false,
      "isp": false,
      "org": false,
      "as": false,
      "asname": false,
      "reverse": false,
      "mobile": false,
      "proxy": false,
      "hosting": false,
      "query": true
    };

    this.handleApiCall(
      this.apiService.getApiData<IpGeoResponse>(
        {
          url: IpGeolocation.post_IpGeolocation,
          method: 'POST'
        },
        geoLocationData),
      this.dataStateService.getGeoLocationData.setState
    );
  }

  private handleApiCall(apiCall: Observable<any>, setState: (data: any) => void, onSuccess?: (response: any) => void) {
    this.dataStateService.handleApiCall(apiCall, setState)
      .pipe(this.untilDestroyed())
      .subscribe({
        next: response => {
          if (onSuccess) {
            onSuccess(response);
          }
        },
        error: err => {
          console.log('error', err);
          // Assuming 'setState' has a way to determine the event name
          const eventName = this.dataStateService.getEventNameForSetState(setState);
          if (err.messages) {
            const errorMessage = err.messages.join(', ');
            this.toastService.show(getToastMessage(ToastMessages.StateApiMessage.error, {
              eventName,
              data: errorMessage
            }));
          }
        }
      });
  }

  private checkNavigatorStatus() {
    this.online$ = merge(
      of(navigator.onLine), // Initial online status
      fromEvent(window, 'online').pipe(mapTo(true)), // Event when online
      fromEvent(window, 'offline').pipe(mapTo(false)) // Event when offline
    ).pipe(
      this.untilDestroyed(),
      startWith(this.previousOnline), // Start with current online status
      pairwise() // Pair current and previous values
    ).subscribe(([prev, curr]) => {
      console.log('Online status changed:', curr);
      if (curr && !prev) {
        this.toastService.show(ToastMessages.InternetConnection.success);
      } else if (!curr && prev) {
        this.toastService.show(ToastMessages.InternetConnection.error);
      }
      this.previousOnline = curr;
    });
  }

  private handleDevice() {
    this.generalService.deviceKind.pipe(
      takeUntilDestroyed(this.destroy),
      switchMap((deviceKind: any) => {
        this.generalService.deviceIs.set(deviceKind);
        return of(this.generalService.deviceIs());
      })
    ).subscribe();
  }
}
